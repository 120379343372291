@import 'style/login';
@import 'style/sizes';

.tooltipTrigger {
  font-weight: $semi-bold;
  text-decoration: underline;
  cursor: pointer;
}

.tooltip {
  position: relative;
  padding: 16px 15px;
}

.tooltipClose {
  position: absolute;
  top: 10px;
  right: 10px;
}

.qr {
  margin-top: 18px;

  img {
    width: 100px;
    height: 100px;
  }
}

.totpDetails {
  margin-top: 6px;
  line-height: 19px;
}

.content {
  padding: 32px $modal-padding-x $modal-padding-y;
}

.loginLayoutContent {
  padding: 0 4px;
}

.successMessage {
  padding: 0 $modal-padding-x;
}

.phoneField {
  margin-right: 12px !important;
}
