@import 'style/colors';
@import 'style/sizes';

@mixin item-padding($additional-padding: 16px) {
  padding: 8px 12px;

  &:first-child {
    padding-top: $additional-padding;
  }

  &:last-child {
    padding-bottom: $additional-padding;
  }
}

.item {
  @include item-padding;

  font-size: $font-size-default;
  list-style: none;
  cursor: pointer;
}

.optionsList {
  padding: 0;
  margin: 0;
}

.groupTitle {
  @include item-padding;

  font-size: $font-size-sm;
  font-weight: $semi-bold;
  color: $secondary-900;
  list-style: none;
}
