@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/OpenSans-Light-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 100;
  src: url('./fonts/OpenSans-LightItalic-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/OpenSans-Regular-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: normal;
  src: url('./fonts/OpenSans-Italic-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/OpenSans-Semibold-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/OpenSans-SemiboldItalic-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/OpenSans-Bold-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/OpenSans-BoldItalic-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/OpenSans-ExtraBold-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url('./fonts/OpenSans-ExtraBoldItalic-webfont.woff') format('woff');
}
