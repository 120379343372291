@import 'style/sizes';
@import 'style/colors';

.container {
  position: relative;
  height: 160px;
  margin-bottom: $spacer-xxs;
  background-color: rgb(255 255 255 / 7%);
}

.header {
  padding: 8px;
  color: $secondary-50;
  text-align: left;
}

.title {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
