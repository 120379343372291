.tableContainer {
  margin-top: 40px;
}

.downloadButton {
  position: absolute;
  top: 30px;
  right: 78px;
  z-index: 999;
  color: #fff !important;
  background-color: transparent !important;
}
