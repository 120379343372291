.container {
  position: relative;
  height: 100%;
  padding: 0;
  margin: 0;
}

.errors {
  max-height: 60px;
  overflow-y: auto;
}
