.controls {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  display: none;
}

.videoWrapper {
  position: relative;

  &.deleted {
    opacity: 0.6;
  }

  &:hover .controls {
    display: block;
  }
}

.video {
  max-width: 100%;
}
