@import 'style/sizes';
@import 'style/fonts';
@import 'style/colors';

$container-padding-x: 12px;
$container-padding-y: 22px;

.container {
  position: absolute;
  width: 275px;
  font-size: 11px;
  border-radius: 8px;
  box-shadow: 0 4px 16px #08366840;
}

.title {
  padding: 12px $container-padding-y;
}

.map {
  width: 60px;
  height: 60px;
  margin: 6px;
  background-color: skyblue;
}

.mapStylePicker {
  padding: $container-padding-x 10px;
}
