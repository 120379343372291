@import 'style/colors';
@import 'style/sizes';

.icon {
  margin-left: 1px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  cursor: pointer;
}

.item:not(:last-child) {
  margin-bottom: $spacer-sm;
}

.split {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  cursor: pointer;
}
