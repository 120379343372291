.colourBar {
  width: 100%;
  height: 11px;
}

/* stylelint-disable selector-class-pattern  */
.select__single-value {
  width: 100%;
}
/* stylelint-enable */
