@import 'style/colors';
@import 'style/sizes';

.accordionTitle {
  padding: 14px 16px;
  font-size: $font-size-lg;
  font-weight: 600;
  line-height: 160%;
  color: #000 !important;
  background: $secondary-50 !important;
  border: 1px solid $secondary-100;
  border-radius: 4px;

  &:hover {
    color: #000 !important;
    background: $secondary-50 !important;
  }
}

// stylelint-disable-next-line
.accordionTitle:global.accordionExpanded {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.collapseDiv {
  width: auto !important;
  margin-right: 8px !important;
  margin-left: 0 !important;
  border: none !important;
}

.chevronIcon {
  padding: 0;
}

.accordionBody {
  > div {
    padding: 0 !important;
    background-color: inherit !important;
    border: none !important;
  }
}

.accordionContent {
  padding: 12px;
  background: $secondary-50;
  border: 1px solid $secondary-100;
  border-top: none;
  border-radius: 0 0 4px 4px;
}
