@import 'style/sizes';
@import 'style/colors';

.iconCollection {
  max-height: 176px;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  list-style: none;
}

.iconCollectionBlock {
  display: inline-block;
  padding: $spacer-xxs;
  cursor: pointer;
  background-color: white;
}

.iconCollectionItem {
  width: 128px;
  height: 128px;
  zoom: 0.18;
}

.iconCollectionRow {
  padding: 0;
  margin: $spacer-xxs;
}
