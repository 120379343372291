@import 'style/colors';

.buttonGroupContainer {
  display: inline-flex;
  align-items: center;
}

.linkButton {
  padding: 0 8px !important;

  &:not(:last-child) {
    border-right: 1px solid $secondary-100 !important;
  }
}

.secondaryLinkButtonColour {
  color: $secondary-100 !important;
}
