.loading {
  height: 80vh;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
}

.name {
  width: 100%;
  font-size: 20px;
  color: #838181;
  background: transparent;
  border: none;

  &:focus {
    outline: none;
  }
}

.hr {
  margin: 4px;
}

.toggle {
  width: 160px;
  text-align: right;
}
