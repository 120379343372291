@import 'style/mixin';

.titleWrapper {
  min-width: 0;
}

/* stylelint-disable -- To enable truncating Multiple Line Text */
.title {
  @include multi-line-ellipsis(
    $line-height: 19.5px,
    $max-height: 39px,
    $lines-to-show: 2
  );
}
/* stylelint-enable */
