.list {
  padding: 0 25px;
}

.time {
  margin-left: 10px;
  color: rgb(0 0 0 / 50%);
}

.name {
  color: #1991ea;
}

.item {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.textInput {
  display: block;
  width: 100%;
  margin: 5px 0;
}
