@import 'style/mixin';
@import 'style/colors';

.text {
  display: block;
  margin: 8px;

  @include rich-text-editor-table-styles;

  table {
    th {
      color: black;
    }
  }

  a {
    color: $blue-500;
    text-decoration: underline;
  }
}
