@import 'style/colors';
@import 'style/sizes';

.arrowBack {
  margin-left: 20px;
  color: #f3f3f3;
  cursor: pointer;
}

.avatar {
  margin-left: 4px;
}

.title {
  margin-left: 10px;
  font-size: 20px;
  color: #fffdfd;
}

.buttonClassName {
  margin-left: 10px;
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }
}

.iconClassName {
  color: rgb(255 255 255 / 50%);
}
