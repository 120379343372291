@import 'style/colors';
@import 'style/sizes';
@import 'style/fonts';

.row {
  margin-bottom: 26px;
}

.form {
  margin-top: 24px;
  margin-bottom: 24px;
}
