@import 'style/colors';

.container {
  flex-shrink: 0;
}

.btn {
  padding: 6px 8px !important;
}

.photosCount {
  font-size: 12px;
  color: $secondary;
}
