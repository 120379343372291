/* stylelint-disable */

.active {
  svg {
    opacity: 1;
  }
}

.svgButton {
  color: white;
  cursor: pointer;
  background: none;
  border: none;

  svg {
    width: 16px;
    opacity: 0.8;
    fill: white;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}

.iconButton {
  svg {
    fill: white;
  }

  &:hover {
    svg {
      opacity: 0.8;
    }
  }
}

.widgetIcon {
  display: flex;
  align-items: center;
  width: 16px;
}

.range {
  width: 160px;
}

/* stylelint-enable */
