@import 'style/sizes';

$border: 1px solid #ddd;

.accordion {
  margin: 0;
  background-color: white;
  border-right: $border;
  border-bottom: $border;
  border-left: $border;

  &:first-child {
    border-top: $border;
    border-radius: 5px 5px 0 0;
  }

  &:last-child {
    border-radius: 0 0 5px 5px;
  }
}

.section {
  margin: 8px 0 24px;
}

.settingsMenuItem {
  transition: all 0.1s ease;
}

.settingsMenuItem:hover {
  cursor: pointer;
}

.accordionAddOnText {
  font-weight: $semi-bold;
}

.title {
  font-weight: $semi-bold;
}

.select {
  width: 300px !important;
}

.bladeSection {
  margin-bottom: 35px;
}
