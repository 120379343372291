@import 'style/sizes';

.noData {
  width: 100%;
  height: 119px;
  margin-top: 47px;
  text-align: center;
  background: url('/svg/Placeholder-bg-Image.svg') no-repeat center center;

  h5 {
    padding-top: 40px;
    font-size: 15px;
    font-weight: $semi-bold;
  }

  p {
    font-size: 13px;
  }

  .btn {
    margin-top: $spacer-xxs;
  }
}
