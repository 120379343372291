@import 'style/colors';
@import 'style/sizes';

.monthLabel {
  font-size: 16px;
  font-weight: $semi-bold;
  color: $dark;
}

.headerSection {
  display: flex;
  justify-content: space-between;
  padding: 4px;
  margin: 6px 12px 0;
  font-size: 15px;
}

.headerLabel {
  margin-top: 2px;
  font-weight: $semi-bold;
}
