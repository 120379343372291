@import 'style/sizes';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  text-align: center;
}

.title {
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: $semi-bold;
  line-height: 22px;
}
