@import 'style/sizes';
@import 'style/fonts';
@import 'style/colors';

.expandButton {
  padding: 2px 7px;
  background-color: #fff;
  border-bottom: 1px solid rgb(0 0 0 / 10%) !important;
  border-radius: 3px 3px 0 0 !important;
}

.container {
  position: absolute;
  padding: 10px;
  margin-left: 42px;
  background-color: white;
  border-radius: $basic-card-border-radius;
}

.closeButton {
  padding-right: 0;
}

.expandButton,
.closeButton {
  border: none;
  border-radius: 3px;

  &:focus {
    outline: none;
  }
}
