@import 'style/sizes';
@import 'style/fonts';
@import 'style/colors';

$description-height: 38px;

.image {
  height: $card-image-height;
  background-position: center;
  background-size: cover;
  border-radius: $basic-card-border-radius $basic-card-border-radius 0 0;
}

.subTitle {
  font-size: 10px;
  color: $secondary;
  text-transform: uppercase;
}

.title {
  height: $description-height;
  font-size: $font-size-default;
  font-weight: $semi-bold;
  line-height: 18px;
  color: $dark;
  text-transform: capitalize;
}

.content {
  margin: $basic-card-margin;
  font-size: 13px;
}

.description {
  height: $description-height;
  overflow: hidden;
  line-height: 18px;
  text-overflow: ellipsis;
}

.note {
  margin-top: 10px;
  line-height: 16px;
}

.cardHeader {
  margin: 12px $basic-card-margin 0;
}
