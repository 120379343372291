@import 'style/colors';

$border-radius: 4px;

.listGroupInput {
  padding-left: 0;
  list-style: none;

  li {
    position: relative;
    display: block;
    min-height: 38px;
    padding: 0;
    margin-bottom: -1px;
    border: 1px solid $secondary-100;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
  }
}

button.add {
  padding: 8px;
}

.btn {
  position: absolute;
  top: -5px;
  right: 0;
}
