@import 'style/colors';

.datePicker {
  position: absolute;
  z-index: 9;
  padding: 0;
  margin-top: 8px;
  background-color: #fff;
  border: 1px solid $secondary-100;
  border-radius: 0.25rem;
  box-shadow: 0 1px 6px -2px #888;
}

.disabledInput {
  background-color: var(--bs-secondary-bg) !important;
  opacity: 0.5;
}

.timePicker {
  padding: 10px;
  background-color: #fafafa;
}
