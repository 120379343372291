@import 'style/sizes';

.passwordToggle {
  position: absolute;
  top: calc($input-padding-y / 2);
  right: 15px;
}

.meter {
  height: 8px !important;
  margin-top: 6px;
}

.bar {
  margin-right: 7px;
  border-radius: 2px;

  &:last-child {
    margin-right: 0;
  }
}

.suggestion {
  margin-top: 6px;
  font-size: 14px;
  font-weight: $regular;
}
