@import 'style/colors';

.center {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 44px;
  color: $secondary-50;
  text-align: center;

  svg {
    fill: $secondary-50;
  }
}

.text {
  margin-top: 10px;
  font-size: 14px;
}
