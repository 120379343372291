@import 'style/colors';

.toolbarButton {
  // stylelint-disable-next-line
  :global .btnIcon {
    margin: 0 6px !important;
  }
}

.clearIcon {
  cursor: pointer;
}

.toolboxItem {
  position: relative; /* Necessary for positioning the pseudo-element */
  padding: 0 10px;

  &:not(:last-child)::after {
    position: absolute;
    top: 50%;
    right: 0;
    height: 20px; /* Adjust this value to set the height of the divider */
    content: '';
    border-right: 1px solid $secondary-100;
    transform: translateY(-50%);
  }
}

.inactiveButton {
  color: $secondary !important;
}
