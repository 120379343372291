@import 'style/colors';
@import 'rc-slider/assets/index.css';

/* stylelint-disable selector-class-pattern */

.rc-slider-handle {
  z-index: 1;
  background-color: $white;
  border-color: var(--primary-500) !important;
  box-shadow: 2px 4px 10px #00000040 !important;
  opacity: 1;

  &:hover {
    background-color: var(--secondary-light-500);
  }

  &:active {
    background-color: var(--secondary-light-600);
  }
}

.rc-slider-tooltip {
  z-index: 10;
}

.rc-slider-disabled {
  background-color: transparent;
  opacity: 0.5;
}

.rc-slider-dot {
  border-width: 1.5px;

  &.rc-slider-dot-active {
    border-color: var(--primary-500);
  }
}

/* stylelint-enable selector-class-pattern */
