.colourBlock {
  display: inline-block;
  width: 16px;
  margin-right: 10px;
}

.colourLabel {
  display: inline-block;
  text-transform: capitalize;
}
