@import 'style/sizes';
@import 'style/colors';

$line-height-base: 166%;

.container {
  padding-bottom: 16px;
  border-bottom: 1px solid $secondary-50;
}

.title {
  font-size: $font-size-default;
  font-weight: $semi-bold;
  line-height: $line-height-base;
}

.subtitle {
  font-size: 12px;
  font-weight: $semi-bold;
  line-height: $line-height-base;
  color: $secondary-500;
  letter-spacing: 0.24px;
}

.content {
  margin-top: 5px;
  font-size: $font-size-default;
  line-height: $line-height-base;
  color: $secondary-500;
}
