@import 'style/colors';

.bladeFooter {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: $light;
  border-top: 1px solid $secondary-100;
}
