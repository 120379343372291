@import 'style/fonts';
@import 'style/sizes';

.input {
  width: 100%;
  padding: 0;
  background-color: transparent;
  border: none;

  &:hover {
    cursor: text;
  }
}

.titleBadge {
  margin-left: $spacer-xs;
  line-height: 15px;
}
