@import 'style/sizes';

.avatar {
  background-color: white;
  background-position: top middle;
  background-size: cover;
  border-radius: 50%;
}

.iconAvatar {
  background-color: transparent !important;
}

@mixin avatar($size) {
  width: $size;
  height: $size;
  text-align: center;
  background-color: white;
  border-radius: 50%;

  &:hover {
    text-decoration: none !important;
  }

  .initials {
    position: relative;
    font-size: $size * 0.4;
    font-weight: bold;
    line-height: $size;
    color: #fff;

    &:hover {
      text-decoration: none !important;
    }
  }
}

.avatarHoverIcon {
  position: relative;
  color: #fff;
}

.avatarCircleXSmall {
  @include avatar(24px);
}

.avatarCircleSmall {
  @include avatar(28px);
}

.avatarCircle {
  @include avatar(42px);
}

.avatarCircleLarge {
  @include avatar(46px);
}

.avatarCircleXLarge {
  @include avatar(62px);
}
