.sectionHeader {
  margin-bottom: 10.5px;
}

.settingsHeader {
  display: flex;
  align-items: center;
}

.submit {
  width: 165px;
}

.tab {
  position: relative;
  bottom: -1px;
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 0 2px 10px 0;
  list-style: none;
  border-bottom: none;
}

.panel {
  height: calc(100% - 33px);
}

.parametersListWrapper {
  max-width: 366px;
  padding-left: 8px;
}

.container {
  padding: 20px 27px;
  background-color: #fff;
}

.dateRange {
  width: 300px;
  margin-top: 4px;
}
