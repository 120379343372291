.optionImage {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
}

.optionLabel {
  margin-left: 6px;
}
