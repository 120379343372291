@import 'style/fonts';

.check {
  margin: 70px 0 32px;
  text-align: center;
}

.nextStep {
  margin-bottom: 86px;
}

.subtitle {
  margin-bottom: 32px;
}
