@import 'style/colors';
@import 'style/sizes';

.container {
  padding: 12px 16px;
  background-color: rgb(20 27 31 / 80%);
  border-radius: 4px;
}

.title {
  margin-bottom: 8px;
  font-size: $font-size-extra-sm;
  font-weight: 600;
  color: $white;
  text-transform: uppercase;
}

.legendItem {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
}

.legendItemCheckbox {
  display: none;
}

.legendItemText {
  font-size: $font-size-extra-sm;
  font-weight: 700;
  color: $white;
  text-transform: uppercase;
  user-select: none;
}

.faded {
  opacity: 0.6;
}
