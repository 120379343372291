@import 'style/sizes';
@import 'style/colors';

.mapStylePicker {
  padding-left: 0;
  margin: 0;
  list-style: none;
}

.girdStyle {
  margin: 3px 5.5px;
}
