@import 'style/colors';

.userName {
  font-size: 20px;
  color: #000;
}

.userInfo {
  font-size: 12px;
  color: #808283;
}

.bottomRight {
  float: right;
  width: 24px;
  height: 24px;
  margin-top: -20px;
}
