.toolbox {
  font-size: 11px;
}

.sortText {
  font-size: 12px;
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;
  background: none;
  border: none;

  &:focus {
    outline: none;
  }
}
