@import 'style/colors';
@import 'style/sizes';

.container {
  width: $blade-width-x-large;
  height: 100%;
}

.issuesContainer {
  position: relative;
}

.bulkUpdate {
  padding: 15px 10px;
}
