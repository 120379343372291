.search {
  position: relative;
}

.searchBtn {
  padding: 4px 8px;
}

.select {
  min-width: 150px;
}

.searchBox {
  margin-right: 2px !important;
  margin-left: 10px;
  background-color: #f3f3f3 !important;
  border: none !important;
  outline: none;
}
