@import 'style/colors';
@import 'style/sizes';

.dropdown {
  min-width: 260px;
}

.badge {
  font-size: 12px !important;
}

.buttonSelected {
  margin-left: 4px;
  font-weight: $semi-bold;
}

.reload {
  font-size: 11px;
  color: $primary;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }
}

.row {
  margin-top: 10px;
}

.formFilters {
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #e2e3e4;
  border-radius: 3px;
  box-shadow: 0 3px 6px #00000029;
}

.timePicker {
  position: absolute;
  z-index: 2;
  background: #fff;
  border: 1px solid #e2e3e4;
  border-radius: 3px;
  box-shadow: 0 3px 6px #00000029;
}
