@import 'style/colors';

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.image {
  width: 100%;
  height: auto;
}
