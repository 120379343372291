@import 'style/sizes';

.table {
  th {
    font-size: 11px;
    font-weight: $regular !important;
  }

  margin-bottom: 0 !important;
  font-size: 12px;
}

.cursor {
  cursor: auto;
}

.colourSwatch {
  width: 77.41px;
}

.colourPicker {
  position: absolute;
}
