@import 'style/sizes';
@import 'style/colors';

.container {
  max-width: 250px;
}

.subtitle {
  font-size: $font-size-default;
  font-weight: $semi-bold;
}

.title {
  font-size: $font-size-default;
}

.closeBtn {
  position: absolute;
  top: 12px;
  right: 12px;
}

.infoTooltipOverlay {
  max-width: 250px;
}

.infoIcon {
  color: $secondary;
}
