@import 'style/colors';

.widget {
  svg {
    max-width: 16px;
    max-height: 12px;
    fill: $secondary;
  }
}

.label {
  display: inline-block;
}
