.tooltipDropdown {
  // stylelint-disable-next-line
  :global .rc-tooltip-inner {
    display: flex;
    padding: 0;

    // accessing through div's because the component from 'react-color'
    // is styled using inline styles and has no classNames
    div > div {
      position: static !important;
      background: none !important;
      box-shadow: none !important;
    }
  }
}

.colorPicker {
  // stylelint-disable-next-line
  :global .flexbox-fix {
    display: none !important;
  }
}
