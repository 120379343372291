@import 'style/sizes';
@import 'style/colors';

.colourSwatch {
  padding: $basic-padding-margin-spacing;
  cursor: pointer;
  background: $white;
  border-radius: $basic-border-radius;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 10%);

  .colourDisplay {
    width: 100%;
    height: 11px;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 10%);
  }
}

.colourPicker {
  .colourPopover {
    position: relative;
    z-index: 2;
    width: 270px;
    margin-top: 1px;

    &.right {
      right: 135px;
    }
  }

  .colourCover {
    position: fixed;
    inset: 0;
  }

  /* stylelint-disable selector-class-pattern  */
  .sketch-picker {
    width: initial !important;
    cursor: pointer;
    background-color: transparent;
    border-radius: 0 !important;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 10%) !important;
  }
}
/* stylelint-enable */
