.app {
  display: flex;
  align-items: stretch;
  height: calc(100vh - calc(100vh - 100%));
}

.button {
  position: absolute;
  top: 5px;
  z-index: 10;
  padding: 10px;
  margin-right: 20px;
}
