@import 'style/sizes';

.container {
  padding: $modal-padding-y $modal-padding-x;
}

.dropzone {
  position: relative;
  text-align: center;
}

.fileUpload {
  text-decoration: underline;
}

.text {
  width: 165px;
  margin: auto;
  text-align: center;
}

.slider {
  margin: 10px 5px;
}
