@import 'style/sizes';
@import 'style/colors';

.container {
  position: relative;
  height: calc(100% - 60px);
}

.layout {
  height: 160px;
}
