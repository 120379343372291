@import 'style/sizes';
@import 'style/colors';

.footer {
  height: $card-footer-height;
  padding: $basic-card-footer-margin-y $basic-card-margin;
  border-top: 1px solid #e0e0e0;
}

.avatar {
  margin-right: 10px;
}

.footerBadge {
  color: $white !important;
}
