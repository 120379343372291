@import 'style/sizes';
@import 'style/colors';

.photo {
  position: relative;
  overflow: hidden;
}

.img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.captionContainer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 48px;
  background: rgb(0 0 0 / 50%);
}

.caption {
  flex-grow: 1;
  margin-right: 16px;
  margin-left: 16px;
  overflow: hidden;
  color: #fff;
}

.title {
  overflow: hidden;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtitle {
  overflow: hidden;
  font-size: 11px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.image {
  margin: 8px 0;
}

.remainingImagesCountContainer {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: $font-size-default;
  font-weight: 600;
  color: #000;
}

.blurredImage {
  filter: blur(8px);
}
