@import 'style/colors';
@import 'style/sizes';

.tooltip {
  z-index: 4;
}

.replayButtons {
  display: inline-block;
}

.container {
  height: 124px;
}

.svgButton {
  size: 10px;
  padding: 3px 12px;
  margin: 0 5px;
  color: #fff;
  cursor: pointer;
  background-color: rgb(106 116 133);
  border: none;

  &:focus {
    outline: none;
  }
}

.reload {
  color: $primary;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }
}

.intervalSelect {
  width: 180px;
}

.speedIcon {
  color: #fff;
}

.speedText {
  margin: 0 1px;
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: 1px solid #fff;
  outline: inherit;

  &:hover {
    color: $primary;
    border-bottom: 1px solid $primary;
  }
}

.intervalText {
  margin: 0 1px;
  color: #fff;
  cursor: pointer;
  background: #ffffff12;
  border: none;
  outline: none;

  &:hover {
    background: #ffffff5c;
  }

  &:focus {
    outline: none;
  }
}

.intervalNumber {
  width: 70px;
  color: rgb(255 255 255 / 50%);
  background: transparent;
  border: none;
  border-bottom: 1px solid rgb(255 255 255 / 50%);
}

.toolbox {
  margin-bottom: 10px;
  font-size: 12px;
}

.timePickerContainer {
  min-width: 260px;
}

.timePickerOptionContainer {
  position: absolute;
  bottom: 155px;
  max-width: 260px;
  background-color: rgb(5 27 50 / 90%);
}
