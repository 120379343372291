@import 'style/colors';

.itemContainer {
  position: relative;
  padding: 8px;
  margin-bottom: 8px;
  background-color: $secondary-50;
  border-radius: 5px;
}

.removeBtn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  color: $secondary-900 !important;
}
