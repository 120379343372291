@import 'style/colors';
@import 'style/sizes';

.container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  background-color: $widget-dark-bg;
}

.wrapper {
  height: calc(100% - 40px);
  margin: 20px;
  background-color: rgb(255 255 255 / 7%);
}

.content {
  height: calc(100% - 80px);
  margin: 10px;
}

.titleLine {
  position: relative;
  z-index: 99;
  padding: 15px 10px 0 0;
}

.title {
  margin: 10px 0 0 10px;
  color: #fff;
}

.downloadButton {
  margin-right: 10px;
  color: #fff !important;
  background-color: transparent !important;
}
