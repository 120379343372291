@import 'style/sizes';
@import 'style/colors';

@mixin gallery-make-container($padding-x: $container-padding-x) {
  width: 100%;
  padding-right: $padding-x;
  padding-left: $padding-x;
  margin-right: auto;
  margin-left: auto;
}

@function gallery-breakpoint-min(
  $name,
  $breakpoints: $gallery-grid-breakpoints
) {
  /* stylelint-disable-next-line  scss/no-global-function-names */
  $min: map-get($breakpoints, $name);

  @return if($min != 0, $min, null);
}

@mixin gallery-media-breakpoint-up(
  $name,
  $breakpoints: $gallery-grid-breakpoints
) {
  /* stylelint-disable-next-line  scss/function-no-unknown */
  $min: gallery-breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

/* mixin for multi line */
@mixin multi-line-ellipsis($line-height, $max-height, $lines-to-show) {
  /* stylelint-disable-next-line -- skip auto fix */
  display: -webkit-box;
  max-height: $max-height;
  overflow: hidden;
  line-height: $line-height;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
}

/* mixin for single line */
@mixin single-line-ellipsis($white-space) {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: $white-space;
}

/* mixin for flex center */
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*  mixin for position y center */
@mixin position-y-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/* stylelint-disable */
@mixin rich-text-editor-table-styles {
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    tr {
      @media print {
        page-break-inside: auto;
      }
    }

    td,
    th {
      border: 1px solid $secondary-700;
      box-sizing: border-box;
      min-width: 1em;
      padding: 3px 5px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: #f1f3f5;
      font-weight: bold;
      text-align: left;
    }

    :global .selectedCell:after {
      background: rgba(200, 200, 255, 0.4);
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    :global .column-resize-handle {
      background-color: #adf;
      bottom: -2px;
      position: absolute;
      right: -2px;
      pointer-events: none;
      top: 0;
      width: 4px;
    }
  }
}
/* stylelint-enable */
