@import 'style/sizes';
@import 'style/colors';

.breadcrumb {
  font-size: 11px;
}

.breadcrumbItem {
  color: $primary;
  cursor: pointer;
}

.arrowBack {
  min-width: 14px;
  margin-right: $rem-spacer-xs;
}
