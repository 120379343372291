@import 'style/sizes';

.formWrapper {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: hidden;
}

.form {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.content {
  min-height: 0;
  padding: $modal-padding-y $modal-padding-x;
  overflow: auto;
}
