@import 'style/colors';

.mapContainer {
  width: 100%;
  height: 200px;
}

.container {
  background-color: $light;
  border: 1px solid #ddd;
  border-radius: 0 0 3px 3px;
}

.dragNote {
  padding: 8.5px 0;
}

.note {
  padding: 12px 16px;
}
