@import 'style/sizes';
@import 'style/colors';

.row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  // each row should be 60px
  // adjust padding-y when we have category on top of the name
  padding: 17px 18px;
  margin: 6px 0;
  cursor: pointer;
  background-color: #fff;
}

.name {
  flex: 0 0 30%;
  font-weight: $semi-bold;
}

.status {
  display: flex;
}

.badge {
  margin-right: 60px;
}
