/* stylelint-disable selector-class-pattern, no-descending-specificity, order/properties-order */
div.jsoneditor,
div.jsoneditor-menu {
  border-color: #4b4b4b;
}

div.jsoneditor-menu {
  background-color: #4b4b4b;
}

div.jsoneditor-tree,
div.jsoneditor textarea.jsoneditor-text {
  background-color: #666;
  color: #fff;
}

div.jsoneditor-field,
div.jsoneditor-value {
  color: #fff;
}

table.jsoneditor-search div.jsoneditor-frame {
  background: #808080;
}

tr.jsoneditor-highlight,
tr.jsoneditor-selected {
  background-color: #808080;
}

div.jsoneditor-field[contenteditable='true']:focus,
div.jsoneditor-field[contenteditable='true']:hover,
div.jsoneditor-value[contenteditable='true']:focus,
div.jsoneditor-value[contenteditable='true']:hover,
div.jsoneditor-field.jsoneditor-highlight,
div.jsoneditor-value.jsoneditor-highlight {
  background-color: #808080;
  border-color: #808080;
}

div.jsoneditor-field.highlight-active,
div.jsoneditor-field.highlight-active:focus,
div.jsoneditor-field.highlight-active:hover,
div.jsoneditor-value.highlight-active,
div.jsoneditor-value.highlight-active:focus,
div.jsoneditor-value.highlight-active:hover {
  background-color: #b1b1b1;
  border-color: #b1b1b1;
}

div.jsoneditor-tree button:focus {
  background-color: #868686;
}

/* coloring of JSON in tree mode */
div.jsoneditor-readonly {
  color: #acacac;
}

div.jsoneditor td.jsoneditor-separator {
  color: #acacac;
}

div.jsoneditor-value.jsoneditor-string {
  color: #0f8;
}

div.jsoneditor-value.jsoneditor-object,
div.jsoneditor-value.jsoneditor-array {
  color: #bababa;
}

div.jsoneditor-value.jsoneditor-number {
  color: #ff4040;
}

div.jsoneditor-value.jsoneditor-boolean {
  color: #ff8048;
}

div.jsoneditor-value.jsoneditor-null {
  color: #49a7fc;
}

div.jsoneditor-value.jsoneditor-invalid {
  color: white;
}
