@import 'style/fonts';

.content {
  margin-left: 6px;
  font-size: $font-size-sm;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.icon svg {
  margin-top: -5px;
}
