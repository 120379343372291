@import 'style/sizes';
@import 'style/colors';

.container {
  z-index: 1;
  width: 100%;
  padding: 10px;
  color: #fff;
  background-color: $widget-dark-bg;
}
