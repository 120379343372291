@import 'style/colors';
@import 'style/sizes';
@import 'style/fonts';

.listGroupItem {
  cursor: pointer;
}

.borderLeftBar {
  width: 4px !important;
  min-height: 36px;
  margin-right: 2px !important;
  border-radius: 4px !important;
  transition: background-color 0.1s ease;
}
