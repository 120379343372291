@import 'style/sizes';
@import 'style/colors';

/* stylelint-disable selector-class-pattern  */

.tooltipDropdown {
  .rc-tooltip-inner {
    padding: 0;
  }
}

.rc-tooltip {
  z-index: 99999;
  max-width: 391px;
  margin-left: 5px;
  cursor: default;
  border-radius: $btn-border-radius;
  opacity: 0.98;

  .rc-tooltip-inner {
    color: $white;
    background-color: $dark-blue;
    border: none;
  }

  &.rc-tooltip-placement-top .rc-tooltip-arrow,
  &.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  &.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    bottom: 5px !important;
    border-top-color: $dark-blue;
  }

  &.rc-tooltip-placement-right .rc-tooltip-arrow,
  &.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
  &.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    left: 5px !important;
    border-right-color: $dark-blue;
  }

  &.rc-tooltip-placement-left .rc-tooltip-arrow,
  &.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
  &.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    right: 5px !important;
    border-left-color: $dark-blue;
  }

  &.rc-tooltip-placement-bottom .rc-tooltip-arrow,
  &.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  &.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    top: 5px !important;
    border-bottom-color: $dark-blue;
  }

  &.light {
    .rc-tooltip-inner {
      color: $dark-blue;
      background-color: $white;
      border: none;
      box-shadow: 0 4px 16px rgb(8 54 104 / 25%);
    }

    &.rc-tooltip-placement-top .rc-tooltip-arrow,
    &.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
    &.rc-tooltip-placement-topRight .rc-tooltip-arrow {
      border-top-color: $white;
    }

    &.rc-tooltip-placement-right .rc-tooltip-arrow,
    &.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
    &.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
      border-right-color: $white;
    }

    &.rc-tooltip-placement-left .rc-tooltip-arrow,
    &.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
    &.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
      border-left-color: $white;
    }

    &.rc-tooltip-placement-bottom .rc-tooltip-arrow,
    &.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
    &.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
      border-bottom-color: $white;
    }
  }
}

/* stylelint-enable */
