@import 'style/colors';
@import 'style/sizes';

/* stylelint-disable */

.vis-timeline {
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
  font-size: 11pt;
  background: transparent;
}
.vis-item {
  border: none;
  background-color: rgb(26, 59, 98);
  font-size: 12pt;
  color: #4f5a66;
  box-shadow: none;
}

.vis-item.vis-background .vis-item-content {
  padding: 2px;
}

.vis-item-content {
  font-size: 10px;
  color: #f3f3f34a;
}
.vis-panel.vis-center,
.vis-panel.vis-left,
.vis-panel.vis-right,
.vis-panel.vis-top,
.vis-panel.vis-bottom {
  border-color: rgba(255, 255, 255, 0.05);
}
.vis-item.vis-background {
  background-color: #cbcbcb17;
}
.vis-item,
.vis-item.vis-line {
  border-width: 3px;
}
.vis-item.vis-range {
  border: none;
  border-radius: 4px;
}

.vis-item.vis-selected {
  border: none;
  background-color: #004f89;
}
.vis-time-axis .vis-text {
  color: #4f5a66;
  padding-top: $spacer-xxs;
  padding-left: $spacer-xxs;
  font-size: 11px;
}
.vis-time-axis .vis-text.vis-major {
  font-weight: bold;
}
.vis-time-axis .vis-grid.vis-minor {
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
}
.vis-time-axis .vis-grid.vis-major {
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
}

.vis-rolling-mode-btn {
  background: #2a394b;
}
/* stylelint-enable */
