@import 'style/sizes';
@import 'style/colors';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $modal-overlay-z-index;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.modalWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: calc($modal-overlay-z-index + 10);
  width: 100%;
  height: 100%;
  overflow: visible;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  max-height: calc(100vh - 3.5rem);
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 16px #08366840;

  /* Use this for centering if unknown width/height */
  transform: translate(-50%, -50%);
}

.modalHeader {
  padding: $modal-padding-y $modal-padding-x !important;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  border-bottom: 1px solid #e0e0e0 !important;
}

.modalTitle {
  font-size: 16px;
  font-weight: $semi-bold;
}

.modalBody {
  font-size: 14px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  border: none;
  border-radius: 3px;

  &:focus {
    outline: none;
  }
}

.footer {
  padding: $modal-padding-y $modal-padding-x;
  background-color: #fcfcfc;
  border-top: 2px solid #f6f8f9;
  border-radius: 0 0 10px 10px;
}

.modalContent {
  max-height: calc(100vh - 3.5rem);
}

.content {
  padding: 24px $modal-padding-x;
}

.warningHeader {
  color: #bf8f00 !important;
}

.dangerHeader {
  color: $danger !important;
}

.modalFooter > div {
  border-radius: 0 0 $basic-padding-margin-spacing $basic-padding-margin-spacing;
}
