@import 'style/sizes';

.label {
  font-size: 12px;
  font-weight: $semi-bold;
}

.checkbox {
  width: 15px !important;
  height: 15px !important;
  margin-top: 0.4em !important;
}
