.icon {
  width: 128px;
  height: 128px;
  zoom: 0.1;
}

.bg {
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 50%;
}
