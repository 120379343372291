@import 'style/colors';

.dropdownHeader {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  font-size: 13px;
  color: $secondary;
  cursor: pointer;
  background-color: $secondary-50;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  /* stylelint-disable */
  /* To remove padding from the menu list */
  + div {
    padding-top: 0;
  }
  /* stylelint-enable */
}
