.gallery {
  display: block;
  height: 100%;
  padding: 10px 0 10px 10px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
