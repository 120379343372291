@import 'style/colors';
@import 'style/sizes';

.label {
  margin-left: 5px;
  font-size: 15px;
  text-transform: uppercase;
}

.optionSecondary {
  margin-top: 4px;
  margin-left: 5px;
  font-size: $font-size-sm;
  color: $secondary-500;
  text-transform: none;
}
