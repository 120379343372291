@import 'style/sizes';

.container {
  position: relative;
  height: 100%;
  padding: 0;
  margin: 0;
}

.overlay {
  position: absolute;
  inset: 0;
  z-index: 2;
  background-color: #ffffff96;
}

.dropZone {
  position: absolute;
  inset: 60px;
  z-index: 1;
  background-color: #ffffff96;
  border-color: transparent;
  border-style: dashed;
  border-width: 1px;
}

.map {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 -50% 0 0;
  font-size: 14px;
  text-align: center;
  transform: translate(-50%, -50%);
}

.title {
  font-weight: $extra-bold;
}

.small {
  font-size: 11px;
}

.searchBox {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
}
