@import 'style/colors';
@import 'style/sizes';

.loadingContainer {
  z-index: 999;
  width: 100%;
  height: 100%;
}

.content {
  position: fixed;
  inset: 0;
  height: calc(50% - 50px);
  margin: auto;
  text-align: center;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid $primary; /* Blue */
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
