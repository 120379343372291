@import 'style/sizes';
@import 'style/colors';

.widget {
  position: relative;
  height: 100%;
}

.toolbox {
  position: absolute;
  top: 0;
  right: 2px;
  z-index: 10;
}
