@import 'style/colors';
@import 'style/fonts';
@import 'style/sizes';

.input {
  width: 100%;
  height: $title-editor-height;
  overflow-x: auto;
  font-size: 20px;
  background: transparent;
  border: none;
}

.input:focus {
  outline: 0;
}

.inputGroup {
  position: relative;
  display: flex;
  flex-flow: wrap row;
}

.inputGroup input {
  flex: 0 0 100%;
  max-width: 100%;
}

.inputGroup ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999;
  opacity: 1; /* Firefox */
}

.border {
  width: 0%;
  border-bottom: 1px solid transparent;
  transition: width 0.2s ease;
}

.active .border {
  width: 100%;
}
