.builder {
  height: calc(100vh - 100px);
  overflow: hidden auto;
}

.buttonsGroup {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1;
  width: 265.16px; // buttonGroup width
  margin-right: auto;
  margin-left: auto;
}
