@import 'style/sizes';

.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
}

.description {
  margin-top: 8px;
}

.children {
  margin-top: 5px;
}

.image {
  margin: auto;
  color: #dedede;
}

.imageBackground {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex: 1;
  margin: auto;
  color: #dedede;
}

.imageContainer {
  position: relative;
  width: 300px;
  background-repeat: no-repeat;
  background-position: center;
}
