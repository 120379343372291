@import 'style/colors';

.addonIcons {
  position: absolute;
  top: 50%;
  right: 14px;
  display: flex;
  align-items: center;
  transform: translateY(-50%);
}

.clockIcon {
  display: flex;
  margin-top: 9px;
  margin-right: 10px;
  color: $dark;
}
