.center {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 2.8em;
  text-align: center;
}

.subTitle {
  font-size: 21px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: -0.19px;
  opacity: 0.17;
}
