@import 'style/sizes';
@import 'style/fonts';
@import 'style/colors';

.group {
  text-transform: none;
  background: #fff;
  border: 1px solid $secondary-100;
  border-radius: 0.25rem;
}

.input {
  padding: 10px 15px 10px 5px;
  font-size: 13px;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  padding: 5px 0 0;
  font-size: 12px;
  color: #051b32;
  text-transform: capitalize;

  div {
    height: 28px;
  }
}

.header {
  padding: 0 17px;
  margin: 0;
  text-align: left;
  border-bottom: 1px solid $secondary-100;
}

.hr {
  display: inline-block;
  margin-right: 6px;
  margin-left: 6px;
  font-size: 12px;
  color: #051b32;
  text-transform: capitalize;
}

.timePicker {
  padding: 10px;
  background-color: $light;
}
