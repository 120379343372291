@import 'style/sizes';
@import 'style/colors';

$border-radius-value: 4px;
$border-color: $secondary-50;
$padding: 16px;

@mixin border-radius($position) {
  border-#{$position}-left-radius: $border-radius-value;
  border-#{$position}-right-radius: $border-radius-value;
}

.collapseDiv {
  width: 50px;
  margin-right: -8px;
  margin-left: 8px;
  text-align: center;

  .iconButton {
    cursor: pointer !important;
  }
}

.body {
  padding: $padding;
  background-color: #fff;
  border-color: $border-color;
  border-style: solid;
  border-width: 1px 1px 0;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $padding;
  margin: 0;
  font-size: 14px;
  font-weight: $semi-bold;
  border-color: $border-color;
  border-style: solid;
  border-width: 0 1px;
  transition: all 0.3s ease;

  &:hover {
    .collapseDiv {
      border-color: #fff;
    }

    .iconButton {
      color: inherit;
    }
  }
}

.container {
  border-bottom: 1px solid $border-color;

  &:first-of-type {
    border-top: 1px solid $border-color;

    @include border-radius(top);

    .title {
      @include border-radius(top);
    }
  }

  &:last-of-type {
    @include border-radius(bottom);

    .title:not(.expanded),
    .body {
      @include border-radius(bottom);
    }
  }
}
