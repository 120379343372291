@import 'style/sizes';
@import 'style/colors';
@import 'style/mixin';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
}

.backArrowBtn {
  padding: 0 6px;
  margin-right: 8px;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}

.titleIconContainer {
  display: flex;
  margin-right: 8px;
}

.titleIcon {
  color: $dark;
}

.textContainer {
  padding-right: 16px;
  overflow: hidden;
}

.title {
  font-size: $font-size-lg;
  font-weight: $semi-bold;
  line-height: 1.625;

  @include single-line-ellipsis($white-space: nowrap);
}

.subtitle {
  font-size: $font-size-default;
  line-height: 1.66;
  color: $secondary-500;

  @include single-line-ellipsis($white-space: nowrap);
}

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.headerActionBtn {
  display: flex;
  color: $secondary;
}

.divider {
  width: 1px;
  height: 20px;
  margin: 0 8px;
  background-color: $secondary;
  opacity: 0.3;
}

.closeButton {
  display: flex;
  padding: 6px;
}
