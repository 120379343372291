@import 'style/colors';
@import 'style/sizes';

.optionTitle {
  font-size: $font-size-default;
  line-height: 16px;
  text-transform: none;
}

.optionSecondary {
  margin-top: 4px;
  font-size: $font-size-sm;
  line-height: 1.66;
  color: $secondary-500;
  text-transform: none;
}

.sideLabel {
  color: $secondary-900;
}
