@import 'style/colors';
@import 'style/fonts';

.toolbarContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px;
  background-color: $secondary-50;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.headerOptionsList {
  margin-top: -10px;

  @include editor-typography;

  li {
    padding-right: 10px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: 0;
  }
}

.undoRedoWrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
