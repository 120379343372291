.container {
  margin: 10px;
}

.list {
  padding-left: 0;
  margin-bottom: 5px;
  font-size: 11px;
  list-style: none;

  li {
    display: inline-block;
    width: calc(50% - 8px);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

.title {
  font-size: 12px;
  font-weight: bold;
}
