@import 'style/colors';
@import 'style/sizes';

.label {
  font-size: 12px;
  color: #f3f3f3cc;
}

.value {
  font-size: 14px;
}

.row {
  margin-bottom: 12px;
}

.text {
  font-weight: bold;
  word-break: break-all;
  white-space: pre-line;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 44px;
}

.tagStyle {
  display: block;
  font-size: smaller;
  font-weight: normal;
  line-height: 1;
}
