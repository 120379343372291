@import 'style/sizes';
@import 'style/mixin';
@import 'style/colors';

/* stylelint-disable selector-max-type,scss/dollar-variable-pattern */
body {
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

h1 {
  font-size: 40px;
  font-weight: $semi-bold;
}

h2 {
  font-size: 24px;
  font-weight: $semi-bold;
}

h3 {
  font-size: 20px;
  font-weight: $semi-bold;
}

h4,
.subtitleText {
  font-size: 20px;
  font-weight: $regular;
}

h5,
.largeText {
  font-size: $font-size-lg;
  font-weight: $semi-bold;
}

h6,
.largeBoldText {
  font-size: $font-size-lg;
  font-weight: $semi-bold;
}

.defaultBoldText {
  font-size: $font-size-default;
  font-weight: $semi-bold;
}

.defaultText,
.defaultButtonText {
  font-size: $font-size-default;
  font-weight: $regular;
}

.defaultText {
  color: #000;
}

.boldSmallText,
.tableHeader,
.subNavActive {
  font-size: $font-size-sm;
  font-weight: $semi-bold;
}

.tableCell,
.subNav,
.smallText {
  font-size: $font-size-sm;
  font-weight: $regular;
}

.pillValue {
  font-size: $font-size-extra-sm;
  font-weight: $extra-bold;
  text-transform: uppercase;
}

.badge,
.status,
.pillLabel {
  font-size: $font-size-extra-sm;
  font-weight: $semi-bold;
  text-transform: uppercase;
}

.extraSmallText {
  font-size: $font-size-extra-sm !important;
  font-weight: $regular;
}

.tinyText,
.navText {
  font-size: $font-size-tiny;
  font-weight: $semi-bold;
}

/* stylelint-enable scss/dollar-variable-pattern */

.semiBold {
  font-weight: $semi-bold;
}

.bold {
  font-weight: $extra-bold;
}

@mixin editor-typography {
  font-size: 14px;
  line-height: 1.35;

  ul,
  ol {
    padding: 0 2rem;
    margin-bottom: 0.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5rem;
  }

  h1 {
    font-size: 40px;
    font-weight: $semi-bold;
    line-height: 1.375;
  }

  h2 {
    font-size: 24px;
    line-height: 1.375;
  }

  h3,
  h4 {
    font-size: 20px;
    line-height: 1.35;
  }

  h3 {
    font-weight: $semi-bold;
  }

  h5,
  h6 {
    font-size: 16px;
    line-height: 1.375;
  }

  h5 {
    font-weight: $semi-bold;
  }

  p {
    margin-bottom: 0.5rem;
    font-size: 14px;
    line-height: 1.35;
  }

  mark {
    padding: 0;
  }

  strong {
    font-weight: $semi-bold;
  }
}
