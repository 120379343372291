@import 'style/colors';
@import 'style/fonts';

.statusBar {
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 1;
  padding: 0 10px;
  margin: 0;
  font-size: 8px;
  color: white;
  text-align: right;
  background-color: #051b3247;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 3.5px 0;
    }
  }
}

.dot {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin-left: 5px;
  border-radius: 50%;
}

.live {
  background-color: $primary;
}

.offline {
  background-color: #f50505;
}

.warning {
  background-color: #ea8a19;
}
