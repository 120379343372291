@import 'style/colors';

.liveBar {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  background-color: #04477b;
  border-radius: 8px;
}

.text {
  margin: 2px;
}

.blink {
  color: $danger;
  animation: blinker 0.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
