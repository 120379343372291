@import 'style/sizes';

.list {
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  align-items: center;
  min-width: 180px;
  padding: 6px;
  font-size: 10px;
  text-transform: uppercase;
  list-style: none;
  cursor: pointer;
  border: 1px solid #e1e4e6;

  &:hover,
  &:focus,
  &:active {
    background-color: #f4f7fa;
  }
}

.subtitle {
  margin-bottom: 6px;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
}

.optionsContainer {
  padding: 10px;
}

.addButton {
  height: 24px;
  padding: 2.5px 8px !important;
}
