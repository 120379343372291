@import 'style/sizes';

.authTypeWrapper {
  padding: 32px 0;
  margin-top: 0 !important;
}

.textContainer {
  border-right: 2px solid #e6e6e6;
}

.authTypeTitle {
  margin: 2px 0;
  font-size: 14px;
  font-weight: $semi-bold;
  color: #000;
  letter-spacing: 0;
  opacity: 1;
}

.authTypeInfo {
  font-size: 14px;
  font-weight: $regular;
  color: #000;
  letter-spacing: 0;
  opacity: 1;
}

.defaultBtn {
  position: relative;
  width: 60px;
  height: 22px;
  margin-top: -24px;
  margin-left: 24px;
  font-size: 10px;
  font-weight: bold;
  line-height: 22px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background-color: #7a7a7a;
  border-radius: 5px;
  opacity: 1;
}

.twoStepAuthCard {
  margin-top: 24px;
}
