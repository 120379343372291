@import 'style/colors';

/* stylelint-disable */
$datepicker__background-color: #fff !default;
$datepicker__border-color: #ccc !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: $primary !default;
$datepicker__text-color: $dark !default;
$datepicker__time-text-color: $secondary-500 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten(
  $datepicker__muted-color,
  10%
) !default;

$datepicker__border-radius: 2rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: 'Open Sans', Helvetica, Arial, sans-serif !important;
$datepicker__item-size: 2rem !default;
$datepicker__item-height: 24px !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.45rem !default;
$datepicker__triangle-size: 8px !default;

$datepicker__time-list-width: 54px !default;

/* stylelint-enable */
