@import 'style/colors';
@import 'style/sizes';
@import 'style/mixin';

.handle {
  left: 7px;
  cursor: pointer;

  path {
    fill: $secondary-600;
  }
}

.trashIcon {
  right: 4px;

  @include flex-center;
}

.item {
  position: relative;
  display: block;
  width: 100%;
  min-height: 38px;
  padding: 0;
  margin: 0;
  border: 1px solid $secondary-100;

  .handle,
  .trashIcon {
    opacity: 0;

    @include position-y-center;
  }

  &:hover,
  &:focus {
    .trashIcon,
    .handle {
      opacity: 1;
    }
  }

  input {
    display: block;
    width: 100%;
    padding: 10px 35px 10px 20px;
    font-size: $font-size-sm;
    color: $secondary-900;
    background: transparent;
    border: none;
  }
}

input.optionText {
  padding: 10px 20px;
}

input.optionValue {
  color: rgba($secondary-900, 0.7);
  background: $light;
}

.borderLeft {
  border-left: 1px solid $secondary-100;
}
