@import 'style/colors';
@import 'style/sizes';

.timeline {
  position: absolute;
  bottom: 10px;
  left: 65px;
  z-index: 12;
  padding: 12px;
  color: #fff;
}

.buttonsGroup {
  margin-bottom: 10px;
  text-align: center;
}

.expandButton {
  position: absolute;
  bottom: 140px;
  z-index: 2;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0 0 10px 25px;
  border: 0;
  border-radius: 0 0 3px 3px !important;
  outline: none;
}
