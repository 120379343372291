@import 'style/colors';
@import 'style/sizes';

.signin {
  z-index: 1;
  width: 100%;
  max-width: 382px;
  margin: auto;
}

.page {
  display: flex;
  align-items: center;
  height: 100vh;
  padding-top: 40px;
  padding-bottom: 40px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgb(0 0 0 / 20%);
  }
}

.logo {
  max-height: 56px;
}

.logoContainer {
  padding: 20px 0;
}

.hr {
  margin: 0 6px;
  border: 1px solid #e1e1e1;
}

.form {
  margin: 24px 18px;
}

.footer {
  padding: 16px;
  text-align: center;
  background-color: #f1f5f8;
  border-radius: 0 0 10px 10px;
}

.poweredBy {
  display: block;
  width: 60px;
  margin: auto;
  font-size: 0.4rem;
  color: #000;

  &:hover {
    color: #000;
  }
}

.poweredBy img {
  width: 100%;
}
