@import 'style/colors';

.blade {
  display: flex;
  flex: initial;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  max-height: 100vh;
  overflow: hidden;
  background-color: $white;
  box-shadow: 0 4px 16px $blade-shadow-color;
}
