/* stylelint-disable selector-class-pattern, no-descending-specificity */

.srd-diagram {
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  cursor: move;
}

.srd-diagram__selector {
  position: absolute;
  background-color: rgb(0 192 255 / 20%);
  border: solid 2px $primary;
}

.srd-link-layer {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: visible !important;
  transform-origin: 0 0;
}

.srd-node-layer {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transform-origin: 0 0;
}

.srd-node {
  position: absolute;
  pointer-events: all;
  cursor: move;
  user-select: none;
}

.srd-node--selected > * {
  border-color: $primary !important;

  .srd-port {
    background: $primary !important;
  }
}

.srd-port {
  width: 15px;
  height: 15px;
  background-color: $secondary-50 !important;
}

.srd-port:hover,
.srd-port.selected {
  background: $primary !important;
}
