/* stylelint-disable selector-class-pattern  */
.mapboxgl-ctrl-group {
  border-radius: 1px;
}

/* stylelint-enable */

.map {
  height: 100%;
}

.mapLogo {
  position: absolute;
  bottom: -8px;
  left: 95px;
  z-index: 2;
  height: 51px;
}
