@import 'style/colors';
@import 'style/sizes';

.popupTitle {
  width: calc(100% - 30px);
  margin-bottom: 19px;
  color: $secondary-50;
  text-align: left;
}

.popupContent {
  height: calc(100% - 50px);
  overflow-x: hidden;
}

.overflow {
  overflow-y: auto;
}

.iconButton {
  background: transparent;
  border: none;

  &:disabled {
    svg * {
      color: #b1b1b1;
    }
  }
}

.icon {
  color: #fff;
}

.hidden {
  display: none;
}

.buttonGroups {
  position: absolute;
  top: 6px;
  right: $spacer-xxs;
}
