@import 'style/sizes';
@import 'style/colors';

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background {
  background-color: $light;
}
