@import 'style/colors';

.tableLoadingMore {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  background-color: $secondary-50;
}
