@import 'style/sizes';
@import 'style/fonts';
@import 'style/colors';

.card {
  position: relative;
  border-bottom: 3px solid rgb(0 0 0 / 3%);
  border-radius: $basic-card-border-radius;
  box-shadow: $basic-box-shadow;
}
