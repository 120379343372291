@import 'style/sizes';
@import 'style/colors';

.navLink {
  width: calc(#{$left-sidebar-width} - (#{$left-sidebar-nav-padding-x} * 2));
  padding: 7px 0;
  margin-bottom: 16px;
  border-radius: $btn-border-radius;

  &:hover {
    background-color: rgb(255 255 255 / 30%);
  }
}

.active {
  background-color: rgb(255 255 255 / 30%);
}

.sidebarIcon {
  text-align: center;
}

.sidebarLabel {
  margin-top: $spacer-xxs;
  color: #fff;
  text-transform: uppercase;
}

.iconWrapper {
  position: relative;
  width: 100%;
}

.badge {
  position: absolute;
  top: -5px;
  right: 10px;
  padding: 0 4px;
  font-size: $font-size-extra-sm;
  font-weight: $bold;
  line-height: 17px;
  color: $white;
  background-color: $danger;
  border-radius: 2px;
}
