.timeRow {
  font-size: 12px;

  input:focus {
    outline: none;
  }
}

.input {
  width: 100%;
  height: 31px;
  padding: 0;
  text-align: center;
  border: none;
}
