@import 'style/colors';

.placeholderContainer {
  width: 100%;
  padding: 53px 36px 26px;
  text-align: center;
  background-color: $light;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.placeholder {
  position: relative;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba($secondary, 0.2);
}

.mark {
  position: absolute;
  left: 0;
}
