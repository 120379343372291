@import 'style/colors';
@import 'style/sizes';
@import 'style/fonts';

.actionItem {
  border: none;
  border-radius: 0;

  .iconPrepend {
    padding-right: $spacer-xxs;
  }

  .btn {
    padding: $spacer-xxs $spacer-sm;
    font-size: 10px;
    text-transform: none;
    background-color: $white;
  }

  .svgButton * {
    width: 17px;
    fill: rgb(255 255 255 / 50%);
  }

  .svgButton.active * {
    opacity: 1;
    fill: white;
  }
}
