@import 'style/sizes';
@import 'style/colors';

$bar-width: 240px;

.searchButton {
  position: relative;
  background-color: white;

  &:focus {
    outline: none;
  }
}

.searchIcon,
.clearIcon {
  color: $secondary;
}

.clearButton {
  position: absolute;
  top: 1px;
  right: 0;
  background: none;
  border: none;
  border-radius: 3px;

  &:focus {
    outline: none;
  }
}

.input {
  width: $bar-width;
  height: 26px;
  padding: 10px 20px 10px 5px;
  font-size: 11px;
  font-weight: 300;
  border: none;
  border-radius: 0;
  transition: width 1s;
}

.container {
  position: relative;
}

.inputFocused {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  outline: none;
}

.suggestionsContainer {
  display: none;
  text-align: left;
  transition: height 1s;
}

.suggestionsContainerOpen {
  position: absolute;
  z-index: 2;
  display: block;
  width: $bar-width;
  height: 153px;
  overflow: auto;
  font-size: 11px;
  font-weight: 300;
  background-color: #fff;
  border-top: 1px solid #aaa;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.suggestionsList {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.suggestion {
  padding: 2.5px 13px;
  color: #000;
  cursor: pointer;
}

.suggestionHighlighted {
  background-color: #ecf3f7;
}

.sectionContainer {
  border-top: 1px dashed #ccc;
}

.sectionContainerFirst {
  border-top: 0;
}

.sectionTitle {
  padding: 4px 0 4px 13px;
  font-size: 8px;
  color: #4a4a4a;
  text-transform: uppercase;
}
