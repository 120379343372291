@import 'style/colors';
@import 'style/sizes';

.close {
  color: white;
  background: none;
  border: none;

  &:focus {
    outline: none;
  }
}

.badge {
  display: inline-flex;
  align-items: center;
  padding: 3px 8px;
  font-size: $font-size-extra-sm;
  font-weight: $bold;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  border-radius: 2px;

  &.outline {
    border-style: solid;
    border-width: 1px;
  }

  svg {
    width: 10px;
    height: 10px;
  }

  .iconRight {
    display: flex;
    margin-left: 4px;
  }

  .iconLeft {
    display: flex;
    margin-right: 4px;
  }
}

.default {
  color: white;
  background: $secondary;
}

.error {
  color: white;
  background: $danger;

  &.outline {
    color: $red-500;
    background: $red-100;
    border-color: $red-300;
  }
}

.warning {
  color: black;
  background: $warning;

  &.outline {
    color: $yellow-700;
    background: $yellow-100;
    border-color: $yellow-400;
  }
}

.success {
  color: white;
  background: $success;

  &.outline {
    color: $green-600;
    background: $green-100;
    border-color: $green-300;
  }
}

.infoGrey {
  color: $secondary;
  background: $secondary-100;

  &.outline {
    color: $secondary-500;
    background: $secondary-100;
    border-color: $secondary-300;
  }
}

.infoBlue {
  color: white;
  background: $info;

  &.outline {
    color: $blue-600;
    background: $cyan-100;
    border-color: $cyan-300;
  }
}

.lightGray {
  color: $secondary-500;
  background: $secondary-50;
}
