@import 'style/colors';

.addonContainer {
  position: absolute;
  top: 50%;
  right: 12px;
  display: flex;
  gap: 4px;
  align-items: center;
  color: $secondary-500;
  cursor: pointer;
  background-color: #fff;
  transform: translateY(-50%);
}

.labelContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.dropdown {
  min-width: 60px;
}
