@import 'style/sizes';
@import 'style/colors';

.header {
  padding: 8px;
  text-align: left;
}

.name {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
}

.scroll {
  overflow: auto auto;
}
