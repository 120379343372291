.container {
  text-align: center;
}

.list {
  max-height: 250px;
  padding: 0 16px;
  overflow: auto;
}

.listItem {
  &:last-of-type {
    border-bottom: none;
  }
}
