@import 'style/fonts';
@import 'style/colors';

$error-line-height: 18px;
$error-margin: 4px;

.label {
  margin-bottom: 0.5rem;
}

.errorStub {
  height: calc($error-line-height + $error-margin);
}

.error {
  margin-top: $error-margin;
  font-size: $font-size-sm;
  line-height: $error-line-height;
  color: $danger;
}

.content {
  width: 100%;
}

.message textarea {
  height: 120px;
}

.textarea {
  width: 100%;
  font-size: $font-size-sm;
  border: none;

  &::placeholder {
    font-size: $font-size-sm;
  }
}
