@import 'style/colors';
@import 'style/sizes';

.trigger {
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-size: $font-size-sm;
  font-weight: $semi-bold;
  line-height: 1.66;
  color: $secondary-500;
  text-transform: uppercase;
  cursor: pointer;
}
