@import 'style/colors';

$title-size: 16px;

.title {
  font-size: $title-size;
}

.pastDueDisplay {
  color: $danger;
}

.description {
  word-break: break-all;
}

.titleInput {
  width: 80%;
  height: 100%;
  font-size: $title-size;
}

.descriptionInput {
  width: 100%;
}

.severityEdit {
  min-width: 110px;
}

.checkbox {
  position: absolute;
  right: -4px;
  bottom: -6px;
}

.timePickerCustom {
  left: 105;
  margin-right: 20px;
}

.anchor {
  cursor: pointer;
}

.titleDisplay {
  max-width: 220px;
}

.row {
  height: 32px;
}
