@import 'style/colors';
@import 'style/mixin';

.title {
  margin-bottom: 8px;
  font-size: 24px;

  @include multi-line-ellipsis(
    $line-height: 32px,
    $max-height: 64px,
    $lines-to-show: 2
  );
}

.header {
  position: relative;
  z-index: 1;
  padding: 16px 0 24px;
  margin-bottom: 24px;
  background-color: $light;
  box-shadow: inset 0 -14px 15px #00000005;
}

.description {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: $secondary;

  &Truncate {
    @include multi-line-ellipsis(
      $line-height: 20px,
      $max-height: 40px,
      $lines-to-show: 2
    );
  }
}
