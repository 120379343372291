/* stylelint-disable selector-class-pattern, no-descending-specificity */
$pink: #faecee;

/* stylelint-disable scss/dollar-variable-pattern */

/* blue */
$blue-50: #f5f9fc;
$blue-100: #d7ecfb;
$blue-200: #a7d5f7;
$blue-300: #79bef3;
$blue-400: #48a8ee;
$blue-500: #1791ea;
$blue-600: #1074be;
$blue-700: #0a578e;
$blue-800: #0a3a5f;
$blue-900: #031d30;

/* cyan */
$cyan-50: #f0f8fa;
$cyan-100: #dcf1f5;
$cyan-200: #a8e5f0;
$cyan-300: #77dbed;
$cyan-400: #46cfe8;
$cyan-500: #00c3e6;
$cyan-600: #00a2bf;
$cyan-700: #008299;
$cyan-800: #006173;
$cyan-900: #00414d;

/* red */
$red-50: #faeded;
$red-100: #f7d2d2;
$red-200: #f5b8b8;
$red-300: #f28585;
$red-400: #f05454;
$red-500: #e30000;
$red-600: #b01109;
$red-700: #96180f;
$red-800: #730f06;
$red-900: #4d0808;

/* yellow */
$yellow-50: #fcf9ed;
$yellow-100: #faf0d2;
$yellow-200: #fae3a0;
$yellow-300: #fad66e;
$yellow-400: #faca3c;
$yellow-500: #ffc10a;
$yellow-600: #cc8b08;
$yellow-700: #995c06;
$yellow-800: #804205;
$yellow-900: #662d04;

/* green */
$green-50: #f0faf4;
$green-100: #daf5e4;
$green-200: #b7e8ca;
$green-300: #81dba3;
$green-400: #51cf80;
$green-500: #24c25f;
$green-600: #1c994b;
$green-700: #18803e;
$green-800: #136632;
$green-900: #0e4d25;

/* gray */
$secondary-50: #fafafa;
$secondary-100: #dfe0e1;
$secondary-200: #c5c6c9;
$secondary-300: #aaacb0;
$secondary-400: #909298;
$secondary-500: #7a7a7a;
$secondary-600: #5d5f64;
$secondary-700: #45464a;
$secondary-800: #2c2d30;
$secondary-900: #141415;

/* stylelint-enable */
$primary: $blue-500;
$secondary: $secondary-500;
$success: $green-500;
$info: $cyan-500;
$warning: $yellow-500;
$danger: $red-500;
$dark: $secondary-900;
$dark-blue: #051b32;
$light: $secondary-50;
$white: #fff;

// Reusable Box Shadows
$basic-box-shadow: 0 1px 3px #00000014;
$hover-box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
$breadcrumb-bg: transparent;
$tabs-hover: rgb(74 74 74 / 65%);
$tabs-disabled: rgb(74 74 74 / 10%);
$list-hover: rgb(25 145 234 / 5%);
$widget-dark-bg: rgb(5 27 50 / 100%);
$link-decoration: none;
$table-bg: transparent;
$blade-shadow-color: #08366840;
$table-variants: (
  'dark-blue': $dark-blue,
);
$scroll-thumb: rgb(151 155 159 / 70%);
$scroll-track: transparent;
$hr-color: #e4e4e4;
