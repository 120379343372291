.extPopover {
  width: 300px;
}

.extPopoverLink {
  font-size: 14px;
}

.extPopoverLinkInfo {
  margin: 0;
  font-size: 12px;
  color: #b1b1b1;
}

.extFavIcon {
  width: 16px;
  height: 16px;
}

.extFavIcon[src=''] {
  display: none;
}

.extPopoverGotoLinkIcon {
  padding: 8px;
  color: #616368;
  background-color: transparent;
  border-radius: 24px;

  &:hover {
    background-color: #0000000d;
  }
}
